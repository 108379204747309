<template>
  <div class="outDiv">
    <div class="row-one-data animated fadeInUp">
      <span>设备状态：</span>
      <div class="status" :class="online === 1 ? '' : 'active'">
        <span>{{ online === 1 ? "在线" : "离线" }}</span>
      </div>
    </div>
    <div class="meteorological">
      <page-left
        :btnShow="false"
        :config="pageLeftConfig"
        :lists="lists"
        :cycleList="cycleList"
        @changeItems="changeItems"
      ></page-left>
      <div class="right-data">
        <div class="row-top-div" v-if="farmID !== 63">
          <img
            class="expert"
            src="@/assets/image/configure/expert.png"
            alt=""
            @click="navToWeatherScreen"
          />
          <div class="accumulated">
            <div class="num">
              {{ nowShowMonth }}月有效积温：{{
                jiWenNum != 0 ? jiWenNum + "℃" : "无"
              }}

              <div class="change-month">
                <el-date-picker
                  class="my-change-month"
                  v-model="monthValue"
                  type="month"
                  placeholder="选择月"
                  size="mini"
                  @change="changeMonth"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="tips">
              作物在某一段时间内日平均气温与生物学零度之差的总和，其中有效温度是指活动温度与生物学下限温度（{{
                farmID == "59" ? "核桃一般为-30℃" : "水稻一般为8℃"
              }}）之差。
            </div>
          </div>
        </div>
        <div class="row-bottom-div">
          <div class="foot-div">
            <img
              class="foot-img"
              :src="$imgOnlineUrl + '/equipment/meteorological/' + 'icon16.png'"
            />
          </div>
          <div class="col-data col-data-left">
            <div
              class="col-data-item"
              v-for="(t, i) in colDataList1"
              :key="i"
              @click="showLog(t.id)"
            >
              <div
                class="col-item-main"
                :style="{ 'background-color': t.color }"
              >
                <img
                  :src="
                    $imgOnlineUrl +
                    '/equipment/meteorological/' +
                    t.icon +
                    '.png'
                  "
                />
                <span>{{ t.title }}</span>
                <div class="last-data-div" v-if="t.last_data != 0">
                  <VueCountUp
                    class="count-up"
                    :start-value="0"
                    :end-value="t.last_data"
                    :duration="1"
                    v-if="t.last_data"
                    :decimals="
                      String(t.last_data).indexOf('.') + 1 > 1 ? '1' : '0'
                    "
                  />
                  <span>{{ t.data_unit }}</span>
                </div>
                <div v-else>
                  <span class="count-up">{{ t.last_data }}</span>
                  <span>{{ t.data_unit }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="center-img">
            <img
              class="img-top"
              :src="$imgOnlineUrl + '/equipment/meteorological/' + 'imgtop.gif'"
            />
            <img
              class="img-bottom"
              :src="
                $imgOnlineUrl + '/equipment/meteorological/' + 'imgbottom.png'
              "
            />
          </div>
          <div class="col-data col-data-right">
            <div
              class="col-data-item"
              v-for="(t, i) in colDataList2"
              :key="i"
              @click="showLog(t.id)"
            >
              <div
                class="col-item-main"
                :style="{ 'background-color': t.color }"
              >
                <img
                  :src="
                    $imgOnlineUrl +
                    '/equipment/meteorological/' +
                    t.icon +
                    '.png'
                  "
                />
                <span>{{ t.title }}</span>
                <div class="last-data-div" v-if="t.last_data != 0">
                  <VueCountUp
                    class="count-up"
                    :start-value="0"
                    :end-value="t.last_data"
                    :duration="1"
                    v-if="t.last_data"
                    :decimals="
                      String(t.last_data).indexOf('.') + 1 > 1 ? '1' : '0'
                    "
                  />
                  <span>{{ t.data_unit }}</span>
                </div>
                <div v-else>
                  <span class="count-up">{{ t.last_data }}</span>
                  <span>{{ t.data_unit }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="isLogShow"
      width="673px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="setAddClose"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          历史记录
        </div>
        <div class="add-form">
          <div class="per-class">
            <span>选择日期</span>
            <el-date-picker
              class="my-date-picker"
              v-model="dateTime"
              :clearable="false"
              type="date"
              placeholder="选择日期"
              @change="changeDate"
            >
            </el-date-picker>
          </div>
          <v-chart
            class="v-chart"
            autoresize
            :option="line_chart_data"
          ></v-chart>
          <div class="per-class">
            <el-date-picker
              class="my-date-picker"
              v-model="rangeTime"
              :clearable="false"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
            <el-button type="primary" class="btn" @click="exportData">
              数据导出
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import moment from "moment";

import {
  getEquipmentListApi,
  getEquipmentInfoApi,
  getEquipmentChildListApi,
  getEquipmentChildListInfoApi,
} from "@/request/api";
import VueCountUp from "vue-countupjs";
import { mapState } from "vuex";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { use } from "echarts/core";
import { PieChart, BarChart, LineChart } from "echarts/charts";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
use([
  BarChart,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
]);
export default {
  components: {
    VueCountUp,
    VChart,
    pageLeft: () => import("./component/pageLeft.vue"),
  },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    return {
      cycleList: [
        {
          title: "温度",
          brief:
            "核桃属于喜温树种，适宜的年均气温是<span style='color: #f56c6c'>9℃～16℃</span>。核桃树生长适宜温度为<span style='color: #f56c6c'>15～25℃</span>，冬季最低气温不低于<span style='color: #f56c6c'>-10℃</span>。",
        },
        {
          title: "水分",
          brief:
            "大豆对水分的需求也较大，特别是在幼苗阶段和开花期。大豆种子需要吸收等于本身重量<span style='color: #f56c6c'>1.2~1.5倍</span>的水分才能发芽。在生长过程中，土壤应保持湿润，但避免水浸太深导致根部窒息和病害的发生。",
        },
        {
          title: "光照",
          brief:
            "核桃是喜光的阳生树种，进入结果期更需要充足的光照条件。一般要求全年日照时数在<span style='color: #f56c6c'>2000小时</span>，低于1100小时，核壳、核仁均发育不良。在阳坡生长的核桃树通常比阴坡的好，并可提早结果。",
        },
        {
          title: "土壤",
          brief:
            "核桃具有庞大的根系和树体，要求有深厚的土层，土壤pH值在<span style='color: #f56c6c'>5.5～7.5之间</span>为宜。核桃喜疏松、透气、排水良好的土壤，在地下水位过高和粘重的土壤上生长不良。核桃喜钙，在含钙的微酸性至微碱性土壤上生长最好。",
        },
        {
          title: "水分",
          brief:
            "核桃树需要充足的水分来维持其生长发育和果实的形成，适宜的年降水量在<span style='color: #f56c6c'>800～1200毫米</span>之间。但核桃树对水分的需求较为特殊，它喜欢湿润的环境，但同时也需要排水良好的土壤，以防止水分过多导致根部腐烂。",
        },
        {
          title: "海拔高度",
          brief:
            "核桃树的生长适宜海拔在<span style='color: #f56c6c'>1500米以下</span>的地区。",
        },
      ],
      jiWenNum: 0,
      rangeTime: "",
      airTId: "",
      monthDay: undefined,
      monthEnd_day: undefined,
      nowShowMonth: "",
      monthValue: "",
      dateTime: null,
      isLogShow: false,
      line_chart_data: {
        backgroundColor: "#0F243D",
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          interval: 55,
        },
        yAxis: {
          name: "",
          nameLocation: "end",
          nameGap: 14,
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            padding: [0, 0, 0, 30],
          },

          type: "value",
          splitNumber: 6,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#36B77F",
          borderColor: "#36B77F",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 数值：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "line",
            data: [],
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "rgb(54,183,127)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#36B77F",
              color: "#fff",
            },
            areaStyle: {
              color: "rgba(54,183,127,.28)",
            },
          },
        ],
      },
      online: 1,
      channel_id: "",
      table_id: "",
      dataList: [],

      pageLeftConfig: {
        placeholder: "搜索气象设备名称",
        img: this.$imgOnlineUrl + "/equipment/meteorological/icon5.png",
      },
      lists: [],
      colDataList1: [],
      colDataList2: [],
      colDataList3: [
        {
          color: "#3679FC",
        },
        {
          color: "#3BA272",
        },
        {
          color: "#EF6667",
        },
        {
          color: "#9D60B5",
        },
        {
          color: "#F0944C",
        },
        {
          color: "#0FB4BA",
        },
        {
          color: "#36BFFF",
        },
        {
          color: "#84BB6F",
        },
        {
          color: "#AC5EA8",
        },
        {
          color: "#815ACD",
        },
        {
          color: "#7a3616",
        },
        {
          color: "#5d73be",
        },
        {
          color: "#bb3c7c",
        },
      ],
    };
  },
  watch: {
    "$route.query"(newVal) {
      this.channel_id = newVal;
      this.getEquipmentListApi();
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getEquipmentListApi();
  },
  methods: {
    async navToWeatherScreen() {
      this.$router.push({
        name: "weatherScreen",
        query: { id: this.channel_id },
      });
    },
    changeMonth(e) {
      if (e) {
        this.monthDay = moment(e).format("YYYY-MM-DD");
        this.monthEnd_day = moment(this.monthDay)
          .endOf("month")
          .format("YYYY-MM-DD");
        this.nowShowMonth = moment(this.monthDay).startOf("month").format("MM");
      } else {
        this.monthDay = moment().startOf("month").format("YYYY-MM-DD");
        this.monthEnd_day = moment(new Date()).format("YYYY-MM-DD");
        this.nowShowMonth = moment().startOf("month").format("MM");
      }
      this.getEffective(this.airTId);
    },
    async getEffective(child_id) {
      const { code, results } = await getEquipmentChildListInfoApi({
        manor_id: this.farmID,
        table_id: this.lists[0].id,
        child_id,
        day: this.monthDay,
        end_day: this.monthEnd_day,
        act: "average",
      });
      if (code != 0) return;
      this.jiWenNum = 0;
      for (const key in results) {
        if (results[key] > 10) {
          this.jiWenNum += results[key] - 10;
        }
      }
      this.jiWenNum = this.jiWenNum.toFixed(2);
    },
    showLog(id) {
      this.dateTime = moment(new Date()).format("YYYY-MM-DD");
      this.isLogShow = true;
      this.child_id = id;
      this.changeDate();
    },
    async changeDate() {
      let day = moment(this.dateTime).format("YYYY-MM-DD");
      const res = await getEquipmentChildListInfoApi({
        manor_id: this.farmID,
        table_id: this.lists[0].id,
        child_id: this.child_id,
        day,
        end_day: day,
        act: "history",
      });
      if (res.code == 0) {
        this.line_chart_data["xAxis"]["data"] = [];
        this.line_chart_data["series"][0]["data"] = [];
        res.results.data.forEach((a) => {
          let date = new Date(a["x"]);
          let h =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          let xAxis = h + ":" + date.getMinutes();
          this.line_chart_data["xAxis"]["data"].push(xAxis);
          this.line_chart_data["series"][0]["data"].push(a["y"]);
        });
        this.line_chart_data["yAxis"]["name"] =
          res.view.title + res.view.data_unit;
      }
    },
    async exportData() {
      if (!this.rangeTime) {
        this.$notify.error({
          title: "错误",
          message: "请选择日期范围",
        });
        return;
      }
      let day = moment(this.rangeTime[0]).format("YYYY-MM-DD");
      let end_day = moment(this.rangeTime[1]).format("YYYY-MM-DD");
      const res = await getEquipmentChildListInfoApi({
        manor_id: this.farmID,
        table_id: this.lists[0].id,
        child_id: this.child_id,
        day,
        end_day,
        act: "history",
      });
      if (res.code === 0) {
        //列标题，逗号隔开，每一个逗号就是隔开一个单元格
        let str = `时间,数值\n`;
        //增加\t为了不让表格显示科学计数法或者其他格式
        for (let a of res.results.data) {
          var date = new Date(a.x);
          var x =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate() +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes();
          str += x + "\t," + a.y + "\n";
        }
        //encodeURIComponent解决中文乱码
        let uri =
          "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
        //通过创建a标签实现
        let link = document.createElement("a");
        link.href = uri;
        //对下载的文件命名
        link.download =
          this.line_chart_data["yAxis"]["name"] +
          "[" +
          day +
          " 至 " +
          end_day +
          "]数据.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    setAddClose() {
      this.isLogShow = false;
      this.dateTime = "";
      this.rangeTime = "";
      this.line_chart_data["xAxis"]["data"] = [];
      this.line_chart_data["series"][0]["data"] = [];
      this.line_chart_data["yAxis"]["name"] = "";
    },
    changeItems(table_id) {
      this.getEquipmentInfoApi_status(table_id.id);
    },
    async getEquipmentChildListApi(table_id) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
        page_size: 20,
      });
      if (code === 0 && results) {
        results.forEach((item, index) => {
          item["color"] = this.colDataList3[index].color;
          if (item.icon == "airT") {
            this.airTId = item.id;
            this.monthDay = moment().startOf("month").format("YYYY-MM-DD");
            this.monthEnd_day = moment(new Date()).format("YYYY-MM-DD");
            this.nowShowMonth = moment().startOf("month").format("MM");
            this.getEffective(item.id);
          }
        });
        this.colDataList3 = results;
        this.$forceUpdate();
        this.getEquipmentInfoApi_status(table_id);
      }
      tryHideFullScreenLoading();
    },
    async getEquipmentInfoApi_status(table_id) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id,
        act: "status",
      });
      if (code === 0) {
        this.online = results.online;
        for (let key in this.colDataList3) {
          let data1 = this.colDataList3[key].number;
          for (let keys in results.data) {
            if (results.data[keys].number == data1) {
              this.colDataList3[key].last_data = Number(
                results.data[keys].last_data
              );
              // this.colDataList3[key].icon = results.data[keys].alias;
            }
          }
        }
        this.colDataList1 = this.colDataList3.slice(0, 7);
        this.colDataList2 = this.colDataList3.slice(7);
        this.$forceUpdate();
      }
    },
    async getEquipmentListApi() {
      showFullScreenLoading();
      this.channel_id =
        this.$route.query.id || localStorage.getItem("equipmentId");
      const { code, results } = await getEquipmentListApi({
        manor_id: this.farmID,
        channel_id: this.channel_id,
      });
      if (code === 0 && results) {
        this.lists = results;
        this.getEquipmentChildListApi(this.lists[0].id);
      } else {
        tryHideFullScreenLoading();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.outDiv {
  padding: 0 48px;
  .row-one-data {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 0 50px;
    .status {
      display: block;
      width: 55px;
      height: 15px;
      color: chartreuse;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: chartreuse;
        margin-right: 6px;
      }
      &.active {
        color: brown;
      }
      &.active::before {
        background-color: brown;
      }
    }
  }
  .meteorological {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #071a2c;
    justify-content: space-between;
    .right-data {
      width: 1300px;
      height: 800px;
      background: #0e2138;
      background-size: contain;
      display: flex;
      flex-direction: column;
      .row-top-div {
        width: 100%;
        height: 98px;
        margin-bottom: 20px;
        display: flex;
        padding: 0 10px;
        .expert {
          width: 52px;
          margin-right: 20px;
          cursor: pointer;
        }
        .accumulated {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .num {
            font-size: 16px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 20px;
            position: relative;
            /deep/.change-month {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 210px;
              color: #fff;
              .my-change-month.el-date-editor {
                width: 140px;
                .el-input__inner {
                  height: 28px !important;
                  background-color: #081c30;
                  border: 0.005208rem solid #385576;
                  color: #57708d;
                }
              }
            }
          }
          .tips {
            font-size: 14px;
            color: rgb(151, 172, 197);
          }
        }
      }
      .row-bottom-div {
        width: 100%;
        height: 700px;
        display: flex;
        position: relative;

        .foot-div {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          z-index: 1;

          .foot-img {
            width: 100%;
            height: 300px;
          }
        }

        .col-data {
          width: calc((100% - 445px) / 2);
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          z-index: 2;
          &.col-data-right {
            padding-right: 10px;
            align-content: flex-end;
          }
          &.col-data-left {
            padding-left: 10px;
          }
          .hide-box {
            width: 40px;
            height: 12px;
          }

          .col-data-item {
            width: 139px;
            height: 167px;
            background: #081c30;
            border: 1px solid #385576;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;
            margin-right: 13px;
            position: relative;
            cursor: pointer;
            .col-item-main {
              width: 94%;
              height: 94%;
              background: #3679fc;
              border-radius: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              img {
                margin-bottom: 14px;
                width: 58px;
                height: 58px;
              }

              span {
                font-size: 16px;
              }
              .last-data-div {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                span {
                  font-size: 22px;
                  font-weight: bold;
                  margin-bottom: 0px;
                }
              }
            }
          }
        }

        .center-img {
          width: 445px;
          height: 100%;
          display: flex;
          position: relative;
          justify-content: center;
          z-index: 2;

          .img-top {
            height: 102px;
            width: 445px;
            position: absolute;
            top: 90px;
            left: 51.8%;
            transform: translateX(-50%);
          }

          .img-bottom {
            width: 405px;
            height: 510px;
            position: absolute;
            bottom: 0px;
          }
        }
      }
    }
  }
  .big-pop {
    /deep/.el-dialog {
      width: 673px !important;
      background-color: inherit;
      margin-top: 0 !important;

      .el-dialog__header {
        padding: 16px 20px 10px;
      }
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 71px;
      right: 52px;
      font-weight: bold;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 673px;
      height: 861px;
      background: url("../../assets/image/task/popbgc.png") no-repeat center
        center;
      background-size: cover;
      padding: 45px 55px 0 55px;

      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }
      .add-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: scroll;
        height: calc(100% - 150px);

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
        .per-class {
          color: #fff;
          margin-bottom: 20px;
          span {
            margin-right: 20px;
          }
          .btn {
            margin-left: 20px;
          }
        }
        .my-date-picker {
        }
        .v-chart {
          width: 100%;
          height: 500px;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-date-editor--datetimerange.el-input,
      /deep/.el-date-editor--datetimerange.el-input__inner {
        width: 353px;
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border-color: #385576;
      }

      /deep/.el-date-editor .el-range-input {
        background-color: #081c30;
        color: #57708d;
        width: 44%;
      }

      /deep/.el-date-editor .el-range-separator {
        color: #fff;
      }

      /deep/.type .el-input__inner {
        width: 136px;
      }

      /deep/.type .el-select {
        width: 136px;
        margin-right: 26px;
      }

      /deep/.type .el-form-item__content {
        display: flex;
      }

      /deep/.type .el-select:nth-of-type(2) {
        margin-right: 0px;
      }

      /deep/.type-col.el-form-item.type {
        display: flex;
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }

      /deep/.detail-form,
      /deep/.complete-form {
        .el-form-item__content {
          p {
            color: #fff;
          }
        }
      }

      /deep/.detail-form {
        .el-form-item {
          margin-bottom: 15px;
        }

        .el-form-item__label,
        .el-form-item__content {
          line-height: 30px;
        }
      }
    }
  }
}
</style>
